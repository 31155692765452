import React, { useState, useEffect } from "react";
import { object, string } from "prop-types";
import { EditOutlined } from "@ant-design/icons";
import {
  Form,
  Button,
  Modal,
  Input,
  DatePicker,
  Select,
  message,
  InputNumber,
  Typography,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import checkAuth from "../../../utils/checkAuth";
import { isLoading as setLoading } from "../../../redux/shared/sharedActions";
import { useUpdateContract } from "../../../api/contracts";
import { useB2CUsers } from "../../../api/b2cUsers";
import { selectFilter } from "../../../utils/filters";
import RetoolButton from "../../shared/RetoolButton/RetoolButton";
import { displayDate, toCalendarDate } from "../../../utils/dates";
import MoneyInput from "../../shared/MoneyInput";

const { RangePicker } = DatePicker;
const { Link } = Typography;
export default function EditContractButton({ contract, text }) {
  const dispatch = useDispatch();
  const updateContract = useUpdateContract(contract.contractId);
  const { isLoading, isError, error, isSuccess } = updateContract;

  const [form] = Form.useForm();

  const [visible, setVisible] = useState(false);

  const [userOptions, setUserOptions] = useState([]);

  const roles = useSelector(state => state.auth.roles);

  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  const {
    isLoading: isLoadingUsers,
    data: users,
    isError: isUsersError,
  } = useB2CUsers({
    params: { emailOnly: true, role: "client" },
  });

  useEffect(() => {
    if (users && !isLoadingUsers && !isUsersError) {
      const _users = [...users];
      for (const authUser of contract.auth) {
        const userExists = _users.find(u => u.email === authUser);
        if (!userExists) {
          _users.push({
            email: authUser,
          });
        }
      }
      setUserOptions(
        _users
          .map(u => ({
            value: u.email,
            label: `${u.email} ${u.displayName ? `- ${u.displayName}` : ""}`,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      );
    }
  }, [users, isLoadingUsers, isUsersError]);

  useEffect(() => {
    if (contract) {
      form.setFieldsValue({
        name: contract.name,
        contractDates: [
          displayDate(contract.startDate),
          displayDate(contract.endDate),
        ],
        auth: contract.auth,
        state: contract.state,
        optinTarget: contract.optinTarget,
        monthlyApplicantsFee: contract.monthlyApplicantsFee,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract]);

  useEffect(() => {
    if (isError) {
      message.error(error?.message || "An error occurred");
    }
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess) {
      setVisible(false);
      message.success("Contract updated!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  if (!contract || !checkAuth.has(roles, ["admin"])) return null;

  const onSubmit = async () => {
    let values = null;
    try {
      values = await form.validateFields();
    } catch (err) {
      return;
    }

    const { contractDates, name, state, auth, manualTarget } = values;

    const updateBody = {
      name,
      auth,
      startDate: toCalendarDate(contractDates[0]),
      endDate: toCalendarDate(contractDates[1]),
      state,
      manualTarget,
    };

    if (contract.isApplicants) {
      updateBody.monthlyApplicantsFee = Number(values.monthlyApplicantsFee);
    }

    updateContract.mutateAsync({
      body: updateBody,
    });
  };

  const onCancel = () => {
    form.resetFields();
    setVisible(false);
  };

  const validateContractDates = value => {
    if (!value || !value[0] || !value[1]) {
      return Promise.reject(new Error("required"));
    }
    return Promise.resolve();
  };

  return (
    <span>
      <Button
        type="primary"
        key="close"
        icon={<EditOutlined />}
        onClick={() => setVisible(true)}
      >
        {text}
      </Button>

      <Modal
        title={`Edit ${contract.name} contract`}
        visible={visible}
        okText="Submit"
        onOk={onSubmit}
        onCancel={onCancel}
        closable={!isLoading}
        footer={[
          <RetoolButton key="1" contractId={`${contract.contractId}`} />,
          <Button key="2" disabled={isLoading} onClick={onCancel}>
            Cancel
          </Button>,
          <Button key="3" type="primary" onClick={onSubmit} loading={isLoading}>
            Submit
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          name="contractFormInModal"
          initialValues={{
            name: contract.name,
            contractDates: [
              displayDate(contract.startDate),
              displayDate(contract.endDate),
            ],
            auth: contract.auth,
            state: contract.state,
            manualTarget: contract.manualTarget,
            optinTarget: contract.optinTarget,
          }}
        >
          <Form.Item label="State" name="state">
            <Select
              options={[
                {
                  value: "active",
                },
                {
                  value: "closing",
                },
                {
                  value: "paused",
                },
                {
                  value: "done",
                },
                {
                  value: "disqualified",
                },
                {
                  value: "in_review",
                  label: "in review",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "required" }]}
            name="name"
            label="Name"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Contract Dates"
            name="contractDates"
            rules={[
              {
                required: true,
                validator: (rule, value) => validateContractDates(value),
              },
            ]}
          >
            <RangePicker />
          </Form.Item>
          <Form.Item
            label="Authorized Users"
            getValueFromEvent={(value, option) => {
              return option.map(o => o.value);
            }}
            name="auth"
            rules={[{ required: true, message: "required" }]}
          >
            <Select
              disabled={isLoadingUsers}
              filterOption={selectFilter}
              loading={isLoadingUsers}
              mode="multiple"
              showSearch
              options={userOptions}
              placeholder="Type to search authorized users"
            />
          </Form.Item>
          <div style={{ display: "flex" }}>
            <Form.Item
              label="Manual Target"
              name="manualTarget"
              rules={[
                {
                  type: "number",
                },
              ]}
            >
              <InputNumber min="0" step="1" />
            </Form.Item>
            {contract.isApplicants && (
              <Form.Item
                label="Monthly Applicant Fee"
                name="monthlyApplicantsFee"
                style={{ marginLeft: "16px" }}
                rules={[
                  {
                    required: true,
                    message: "valid applicants fee is required",
                  },
                ]}
              >
                <MoneyInput />
              </Form.Item>
            )}
          </div>
        </Form>
      </Modal>
    </span>
  );
}

EditContractButton.propTypes = {
  contract: object,
  text: string,
};
