import { flattenCampaigns } from "../campaigns/campaignUtils";

const getBillingStatus = contract => {
  const billingActive = contract?.relations?.billingContracts?.active;
  if (billingActive === true) {
    return "active";
  }
  if (billingActive === false) {
    return "inactive";
  }
  return "";
};

export const flattenContracts = data => {
  const flattenedContracts = [];
  for (const contract of data) {
    const flattenedCampaigns = flattenCampaigns(contract.relations.campaigns);

    flattenedContracts.push({
      //campaigns
      companyName: contract.relations.companies.name,
      ...contract.relations.companies,
      //stats
      ...contract.stats,
      // ...contract.inviteStats,
      //contract
      ...contract, //last so that name = contract.name
      done: contract.stats.posts || 0,
      totalOnboards: contract.stats.optIns || 0,
      //flattened campaigns from previous function
      relations: {
        ...contract.relations,
        campaigns: flattenedCampaigns,
      },
      billing: getBillingStatus(contract),
    });
  }
  return flattenedContracts;
};
