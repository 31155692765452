import React, { useState } from "react";
import { Form, Button, Typography, Tag } from "antd";
import { object } from "prop-types";
import { useSendUserNotification } from "../../api/users";

function DeviceRegistrationsForm({ user }) {
  const sendUserNotification = useSendUserNotification();
  const [sendingTestNotification, setSendingTestNotification] = useState(false);
  const sendTestNotification = async () => {
    setSendingTestNotification(true);
    try {
      await sendUserNotification.mutateAsync({
        userId: user?.userId,
      });
    } finally {
      setSendingTestNotification(false);
    }
  };

  return (
    <div>Not enabled</div>
    // <Form.Item label="Device Registrations:">
    //   {user &&
    //     user?.relations?.userDeviceRegistrations?.map(udr => (
    //       <Form.Item>{udr.deviceToken}</Form.Item>
    //     ))}
    //   <Form.Item style={{ marginBottom: 0 }}>
    //     <Button
    //       disabled={!user || sendingTestNotification}
    //       type="primary"
    //       onClick={sendTestNotification}
    //     >
    //       Send Test Notification
    //     </Button>
    //   </Form.Item>
    // </Form.Item>
  );
}

DeviceRegistrationsForm.propTypes = {
  user: object,
};

export default DeviceRegistrationsForm;
