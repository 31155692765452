export const cascaderFilter = (inputValue, path) => {
  return path.some(
    option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  );
};

export const selectFilter = (inputValue, option) => {
  return option.label.toLowerCase().includes(inputValue.toLowerCase());
};

// Used for Test Users UI. convert array of options label from a React JSX object into a plain string for searching the label
export const selectFilterForCampaignsListWithTag = (inputValue, option) => {
  const optionValueToSearch = `${option.label.props.children[0]} ${option.value}`;
  return optionValueToSearch.toLowerCase().includes(inputValue.toLowerCase());
};
