import React, { useEffect, useState } from "react";
import { Input } from "antd";
import { useHistory, useParams } from "react-router-dom";
import PostSearchHeader from "../PostHeader/PostSearchHeader";
import PostById from "../PostById";
import PostsSearchDataTable from "./PostsSearchDataTable";
import { useOnboards } from "../../../api/onboards";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent); //if Mobile

export default function PostsSearch() {
  //state
  const [query, setQuery] = useState("");

  //router
  // Render post by ID if we have an ID in the path
  const { optionalPathParam } = useParams();
  const queryParams = new URLSearchParams(useHistory().location.search);
  const searchQueryParam = queryParams.get("search");

  // Query posts when search query is set
  const { data: posts, isLoading } = useOnboards({
    includes: ["payouts", "campaigns", "users", "shortcodes", "campaignFlows"],
    params: {
      search: query,
    },
    enabled: !!query,
  });

  // On mount, if there is a search query parameter, trigger search
  useEffect(() => {
    if (searchQueryParam) {
      triggerSearch(searchQueryParam);
    }
  }, [searchQueryParam]);

  // Helper to trigger our search by setting the query
  const triggerSearch = q => {
    if (!q) return;
    const _q = q.trim();
    setQuery(_q);
  };

  //if id in path, render PostById component. We might have to load data for that onboard.
  if (optionalPathParam) {
    return <PostById onboardId={optionalPathParam} />;
  }

  // Otherwise, render the full posts search list
  return (
    <div>
      <PostSearchHeader>
        <Input.Search
          style={{ width: isMobile ? "100%" : "60vw" }}
          // ref={input => input && input.focus()}
          placeholder="ID, emails, shortcodes, @handles"
          enterButton="Search"
          size="large"
          onPaste={event => triggerSearch(event.clipboardData.getData("text"))}
          // value={"ayor.bush@gmail.com"}
          onSearch={value => triggerSearch(value)}
        />
      </PostSearchHeader>
      {posts && <PostsSearchDataTable posts={posts} />}
    </div>
  );
}
