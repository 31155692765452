import React, { useEffect, useState } from "react";
import { any } from "prop-types";
import { Alert, Form, message, Modal } from "antd";
import AdvancedForm from "./AdvancedForm";
import {
  useMintBillingContract,
  useUpdateBillingContract,
} from "../../../api/billingContract";
import BillingSetup from "./BillingSetup";
import PlatformCreditsConfiguration from "./PlatformCreditsConfiguration";
import ProductCreditsConfiguration from "./ProductCreditsConfiguration";
import DepositDetails from "./DepositDetails";
import GenericProductCreditsConfiguration from "./GenericProductCreditsConfiguration";

export default function BillingContract({
  setup = {},
  contract = {},
  billingContract = {},
}) {
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [billingSetup, setBillingSetup] = useState({ ...setup });
  const [billingSettingsForm] = Form.useForm();
  // Note - for now, because we're sort of piggybacking off of platform credit UI, applicant credits
  // also use this form.
  const [platformCreditsForm] = Form.useForm();
  const [productCreditsForm] = Form.useForm();
  const updateBillingContract = useUpdateBillingContract();
  const mintBillingContract = useMintBillingContract();

  const totalContractTargets = billingContract?.relations?.contracts.reduce(
    (sum, c) => {
      return sum + c.target;
    },
    0
  );

  const resetFormFields = newBillingSetup => {
    billingSettingsForm.setFieldsValue(newBillingSetup);
    platformCreditsForm.setFieldsValue(newBillingSetup);
    productCreditsForm.setFieldsValue(newBillingSetup);
  };

  useEffect(() => {
    if (!setup || confirmModalVisible) {
      return;
    }

    const newBillingSetup = { ...setup };

    // This is crazy, we're munging the form state and the backend object state in just
    // the wildest way.
    if (setup.platformCreditSettings) {
      newBillingSetup.fee = getFee(setup.platformCreditSettings, contract);
      newBillingSetup.serviceFee = getServiceFee(setup.platformCreditSettings);
      newBillingSetup.platformCreditSettings.serviceFee =
        newBillingSetup.serviceFee;
      newBillingSetup.serviceFeeDescription = setup.platformCreditSettings
        .creditSettingsId
        ? setup.platformCreditSettings.serviceFeeDescription
        : "Paypal transaction fee";
      newBillingSetup.platformCreditSettings.serviceFeeDescription =
        newBillingSetup.serviceFeeDescription;

      newBillingSetup.platformCreditSettings.newCredits =
        setup.platformCreditSettings.suggestedCredits || 0;
      newBillingSetup.newCredits = true;
    }

    if (setup.applicantCreditSettings) {
      newBillingSetup.cyclesToAdd = setup.applicantCreditSettings.cyclesToAdd;
      newBillingSetup.newApplicantCredits = true;
    }

    if (setup.productCreditSettings) {
      for (let i = 0; i < setup.productCreditSettings.length; i++) {
        newBillingSetup.productCreditSettings[i].newCredits =
          setup.productCreditSettings[i].suggestedCredits || 0;
        newBillingSetup[`newCredits-${i}`] = true;
      }
    }

    setBillingSetup(newBillingSetup);
    resetFormFields(newBillingSetup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setup]);

  const onSaveBillingSettings = async () => {
    try {
      await billingSettingsForm.validateFields();
    } catch (error) {
      //ignore as Ant Design will handle validation based on form.item rules
    }
    const values = billingSettingsForm.getFieldsValue(true);
    const updatedBillingContract = {
      stripeCustomerId: values.stripeCustomerId,
      active: values.active,
      depositWeight: values.depositWeight,
      invoiceInterval: values.invoiceIntervalWeeks * 7,
    };
    await updateBillingContract.mutateAsync({
      billingContractId: billingSetup.billingContractId,
      updatedBillingContract,
    });
    message.success("Successfully updated billing settings");
  };

  const platformCreditSettings = billingSetup?.platformCreditSettings || {};
  const productCreditSettings = billingSetup?.productCreditSettings || [];
  const applicantCreditSettings = billingSetup?.applicantCreditSettings || {};

  const onInitializeButtonClick = async () => {
    try {
      await platformCreditsForm.validateFields();
      await productCreditsForm.validateFields();
      setConfirmModalVisible(true);
    } catch (err) {
      //ignore as Ant Design will handle validation based on form.item rules
    }
  };

  const getCreditSettingsButtonText = () => {
    if (setup.active) {
      return "Extend";
    }
    if (platformCreditSettings.creditSettingsId) {
      return "Extend";
    }
    for (const productSetting of productCreditSettings) {
      if (productSetting.creditSettingsId) {
        return "Extend";
      }
    }
    return "Initialize";
  };

  const onInitialize = async () => {
    const newProductCreditSettings = [];

    for (const [key, newCredits] of Object.entries(billingSetup)) {
      if (key.startsWith("newCredits-")) {
        const [_, index] = key.split("-");
        const { productId, creditSettingsId } = productCreditSettings[index];
        newProductCreditSettings[index] = {
          // Will be undefined if this is a gen eric product credit
          productId,
          newCredits: newCredits
            ? productCreditSettings[index].suggestedCredits
            : 0,
          // Will be undefined if this is a new product credit setting
          creditSettingsId,
        };
      }
    }

    const newPlatformCreditSettings = {
      newCredits: 0,
      creditSettingsId: platformCreditSettings.creditSettingsId,
    };

    if (billingSetup.newCredits) {
      newPlatformCreditSettings.newCredits =
        setup.platformCreditSettings.suggestedCredits;
      newPlatformCreditSettings.fee = billingSetup.fee;
      newPlatformCreditSettings.serviceFee = billingSetup.serviceFee;
      newPlatformCreditSettings.serviceFeeDescription =
        billingSetup.serviceFeeDescription;
    }

    const mintedBillingContract = {
      platformCreditSettings: newPlatformCreditSettings,
    };

    if (newProductCreditSettings.length > 0) {
      mintedBillingContract.productCreditSettings = newProductCreditSettings;
    }

    if (billingSetup.newApplicantCredits) {
      const newApplicantCreditSettings = {
        cyclesToAdd: billingSetup.cyclesToAdd,
        newCredits: billingSetup.applicantCreditSettings.suggestedCredits,
      };

      mintedBillingContract.applicantCreditSettings =
        newApplicantCreditSettings;
    }

    await mintBillingContract.mutateAsync({
      billingContractId: billingSetup.billingContractId,
      mintedBillingContract,
    });

    const mintedCredits =
      newPlatformCreditSettings.newCredits > 0 ||
      newProductCreditSettings.length > 0;

    const successMessage = mintedCredits
      ? "Successfully created deposit invoice on Stripe!"
      : "Successfully updated product credit settings";

    message.success(successMessage);
  };

  const isInitializeButtonDisabled = () => {
    if (!setup) return false;

    if (!platformCreditSettings.creditSettingsId) {
      return true;
    }

    if (setup.invalidSuggestedCredits) {
      return true;
    }

    if (setup.targets === "misconfigured") {
      return true;
    }
    if (!setup.stripeCustomerId) {
      return true;
    }

    if (
      !["number", "string"].includes(typeof setup.depositWeight) ||
      !setup.invoiceIntervalWeeks
    ) {
      return true;
    }
  };

  const updateBillingSetup = changedValues => {
    setNewCredits(changedValues);
    setBillingSetup({ ...billingSetup, ...changedValues });
  };

  const setNewCredits = changedValues => {
    const changedKey = Object.keys(changedValues)[0];
    if (changedKey.startsWith("newCredits")) {
      const [_, index] = changedKey.split("-");
      const isIncluded = changedValues[changedKey];
      if (Number(index) >= 0) {
        changedValues.productCreditSettings = [
          ...billingSetup.productCreditSettings,
        ];
        const productCreditSetting = changedValues.productCreditSettings[index];
        productCreditSetting.newCredits = isIncluded
          ? productCreditSetting.suggestedCredits
          : 0;
      } else {
        changedValues.platformCreditSettings = {
          ...billingSetup.platformCreditSettings,
        };
        changedValues.platformCreditSettings[changedKey] = isIncluded
          ? changedValues.platformCreditSettings.suggestedCredits
          : 0;
      }
    }
  };

  const getProductCreditsComponent = () => {
    if (productCreditSettings.length === 0) {
      return;
    }
    if (typeof contract.manualTarget === "number") {
      return (
        <AdvancedForm
          title="Generic Product Credits"
          formItems={
            <GenericProductCreditsConfiguration
              productCreditSettings={productCreditSettings}
              billingSetup={billingSetup}
              campaignsByProductId={setup.campaignsByProductId}
            />
          }
          formProps={{
            layout: "horizontal",
            form: productCreditsForm,
            initialValues: billingSetup,
            onValuesChange: changedValues => {
              updateBillingSetup(changedValues);
            },
          }}
        />
      );
    }
    return (
      <AdvancedForm
        title="Product Credits"
        formItems={
          <ProductCreditsConfiguration
            productCreditSettings={productCreditSettings}
            campaignsByProductId={setup.campaignsByProductId}
          />
        }
        formProps={{
          layout: "horizontal",
          form: productCreditsForm,
          initialValues: billingSetup,
          onValuesChange: changedValues => {
            updateBillingSetup(changedValues);
          },
        }}
      />
    );
  };

  return (
    <div style={{ marginBottom: "32px" }}>
      <Modal
        title="Confirm Invoice"
        visible={confirmModalVisible}
        okText="Confirm"
        onOk={async () => {
          try {
            setConfirmLoading(true);
            await onInitialize();
            setConfirmModalVisible(false);
          } catch (err) {
            message.error(err?.message || "An error occurred");
          } finally {
            setConfirmLoading(false);
          }
        }}
        onCancel={() => setConfirmModalVisible(false)}
        confirmLoading={confirmLoading}
        cancelButtonProps={{ disabled: confirmLoading }}
      >
        <DepositDetails
          platformCreditFee={getFee(platformCreditSettings, contract)}
          platformCreditServiceFee={getServiceFee(platformCreditSettings)}
          billingSetup={billingSetup}
          isGenericProductCredits={
            setup.targets === "contract" &&
            typeof contract.manualTarget === "number"
          }
        />
      </Modal>

      <AdvancedForm
        title="Billing Settings"
        formItems={<BillingSetup setup={billingSetup} contract={contract} />}
        formProps={{
          form: billingSettingsForm,
          initialValues: billingSetup,
        }}
        onSubmit={onSaveBillingSettings}
      />

      {setup.invalidSuggestedCredits && (
        <Alert
          message="One or more campaigns has a target less than the number of completed onboards. Please correct the target before adding or deleting more credits."
          type="error"
          showIcon
        />
      )}

      <AdvancedForm
        title="Platform Credits"
        formItems={
          <PlatformCreditsConfiguration
            billingContractId={billingSetup.billingContractId}
            billingSetup={billingSetup}
            platformCreditSettings={platformCreditSettings}
            applicantCreditSettings={applicantCreditSettings}
            target={totalContractTargets}
          />
        }
        formProps={{
          form: platformCreditsForm,
          initialValues: billingSetup,
          onValuesChange: changedValues => {
            updateBillingSetup(changedValues);
          },
        }}
        submitText={getCreditSettingsButtonText()}
        submitButtonDisabled={isInitializeButtonDisabled()}
        additionalComponent={getProductCreditsComponent()}
        onSubmit={onInitializeButtonClick}
      />
      <DepositDetails
        platformCreditFee={getFee(platformCreditSettings, contract)}
        platformCreditServiceFee={getServiceFee(platformCreditSettings)}
        billingSetup={billingSetup}
        isGenericProductCredits={
          setup.targets === "contract" &&
          typeof contract.manualTarget === "number"
        }
      />
    </div>
  );
}

function getFee(platformCreditSettings, contract) {
  return platformCreditSettings?.creditSettingsId
    ? platformCreditSettings?.fee
    : contract.fee;
}

function getServiceFee(platformCreditSettings) {
  return platformCreditSettings?.creditSettingsId
    ? platformCreditSettings?.serviceFee
    : "1.50";
}

BillingContract.propTypes = {
  setup: any,
  contract: any,
  billingContract: any,
};
