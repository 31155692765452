import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Tooltip,
  Typography,
} from "antd";
import { any, number, string } from "prop-types";
import { useWatch } from "antd/lib/form/Form";
import styles from "./BillingContract.module.css";
import MoneyInput from "../../shared/MoneyInput";
import { useUpdateCreditSettings } from "../../../api/creditSettings";

export default function PlatformCreditsConfiguration({
  platformCreditSettings,
  applicantCreditSettings,
  target,
  billingContractId,
  billingSetup,
  form,
}) {
  const [updatingCreditSettings, setUpdatingCreditSettings] = useState(false);
  const updateCreditSettings = useUpdateCreditSettings();

  const onUpdateCreditSettings = async () => {
    try {
      setUpdatingCreditSettings(true);
      await updateCreditSettings.mutateAsync({
        body: {
          billingContractId,
          creditSettingsId: platformCreditSettings.creditSettingsId,
          type: "platform",
          fee: billingSetup.fee,
          serviceFee: billingSetup.serviceFee,
          serviceFeeDescription: billingSetup.serviceFeeDescription,
        },
      });
      message.success("Successfully updated billing settings");
    } catch (err) {
      message.error(`Error: ${err.message}`);
    } finally {
      setUpdatingCreditSettings(false);
    }
  };

  const onUpdateApplicantSettings = async () => {
    try {
      setUpdatingCreditSettings(true);
      await updateCreditSettings.mutateAsync({
        body: {
          billingContractId,
          type: "applicant",
          fee: billingSetup.fee,
        },
      });
      message.success("Successfully updated billing settings");
    } catch (err) {
      message.error(`Error: ${err.message}`);
    } finally {
      setUpdatingCreditSettings(false);
    }
  };

  return (
    <>
      <InstagramPlatformCreditConfiguration
        billingSetup={billingSetup}
        target={target}
        platformCreditSettings={platformCreditSettings}
        onUpdateCreditSettings={onUpdateCreditSettings}
        updatingCreditSettings={updatingCreditSettings}
      />
      {billingSetup.isApplicants && (
        <ApplicantsPlatformCreditConfiguration
          billingSetup={billingSetup}
          target={target}
          applicantCreditSettings={applicantCreditSettings}
          onUpdateApplicantSettings={onUpdateApplicantSettings}
          updatingCreditSettings={updatingCreditSettings}
        />
      )}
    </>
  );
}

PlatformCreditsConfiguration.propTypes = {
  platformCreditSettings: any,
  applicantCreditSettings: any,
  target: number,
  billingContractId: string,
  billingSetup: any,
};

function InstagramPlatformCreditConfiguration({
  billingSetup,
  target,
  platformCreditSettings,
  onUpdateCreditSettings,
  updatingCreditSettings,
}) {
  const getCheckboxLabel = () => {
    const { suggestedCredits } = platformCreditSettings;
    if (suggestedCredits === 0) {
      return "Credits for current target minted";
    }
    return `${suggestedCredits > 0 ? "Add" : "Delete"} ${Math.abs(
      suggestedCredits
    )} credits?`;
  };

  return (
    <>
      <Col span={4} key="creditName" className={styles.textFormColumn}>
        <Typography.Text>
          <span className={styles.formLabel}>Credit Name: </span> Instagram Grid
          Post
        </Typography.Text>
      </Col>

      <Col span={4} key="creditPrice" className={styles.textFormColumn}>
        <Typography.Text>
          <span className={styles.formLabel}>Credit Price: </span> $
          {(Number(billingSetup.fee) + Number(billingSetup.serviceFee)).toFixed(
            2
          )}
        </Typography.Text>
      </Col>

      <Col span={4} key="target" className={styles.textFormColumn}>
        <Typography.Text>
          <span className={styles.formLabel}>Target: </span>
          {target}
        </Typography.Text>
      </Col>

      <Col span={4} key="totalCredits" className={styles.textFormColumn}>
        <Typography.Text>
          <span className={styles.formLabel}>Total Credits: </span>
          {platformCreditSettings.total}
        </Typography.Text>
      </Col>

      <Col span={4} key="availableCredits" className={styles.textFormColumn}>
        <Typography.Text>
          <span className={styles.formLabel}>Available Credits: </span>
          {platformCreditSettings.total - platformCreditSettings.consumed}
        </Typography.Text>
      </Col>

      <Col span={4} key="usedCredits" className={styles.textFormColumn}>
        <span className={styles.formLabel}>Used Credits: </span>
        <Typography.Text>{platformCreditSettings.consumed}</Typography.Text>
      </Col>

      <Col span={4} key="platformFees" className={styles.formColumn}>
        <Form.Item
          name="fee"
          label={<span className={styles.formLabel}>Platform Fees</span>}
          rules={[{ required: true, message: "required" }]}
        >
          <MoneyInput />
        </Form.Item>
      </Col>

      <Col span={4} key="platformServiceFees" className={styles.formColumn}>
        <Form.Item
          name="serviceFee"
          label={
            <span className={styles.formLabel}>Platform Service Fees</span>
          }
          rules={[{ required: true, message: "required" }]}
        >
          <MoneyInput />
        </Form.Item>
      </Col>

      <Col span={4} key="serviceFeeDescription" className={styles.formColumn}>
        <Form.Item
          name="serviceFeeDescription"
          label={
            <span className={styles.formLabel}>Service Fee Description</span>
          }
        >
          <Input />
        </Form.Item>
      </Col>

      <Col
        span={4}
        key="savePlatformCreditSettings"
        className={styles.formColumn}
        style={{ display: "flex", alignItems: "center" }}
      >
        <Button
          type="primary"
          onClick={onUpdateCreditSettings}
          loading={updatingCreditSettings}
          disabled={updatingCreditSettings}
        >
          Save
        </Button>
      </Col>

      <Col
        span={4}
        key="newCredits"
        className={styles.formColumn}
        style={{ display: "flex", alignItems: "center" }}
      >
        <span className={styles.formLabel}>{getCheckboxLabel()}</span>
        <Form.Item
          className={styles.formItem}
          name="newCredits"
          valuePropName="checked"
          style={{ marginBottom: 0 }}
        >
          <Checkbox
            disabled={platformCreditSettings.suggestedCredits === 0}
            style={{ marginLeft: "8px" }}
          />
        </Form.Item>
      </Col>
    </>
  );
}

InstagramPlatformCreditConfiguration.propTypes = {
  billingSetup: any,
  target: number,
  platformCreditSettings: any,
  onUpdateCreditSettings: any,
  updatingCreditSettings: any,
};

// We will likely actually have a separate credit type in the future for applicants, but for now for
// simplicity, we will mock the existing platform credit configuration.
function ApplicantsPlatformCreditConfiguration({
  target,
  applicantCreditSettings,
}) {
  const { monthlyApplicantsFee, suggestedCredits } = applicantCreditSettings;

  const getCheckboxLabel = () => {
    if (suggestedCredits === 0) {
      return "Credits for current target minted";
    }
    return `${suggestedCredits > 0 ? "Add" : "Delete"} ${Math.abs(
      suggestedCredits
    )} credits?`;
  };

  return (
    <>
      <Divider />
      <Col span={4} key="creditName" className={styles.textFormColumn}>
        <Typography.Text>
          <span className={styles.formLabel}>Credit Name: </span> Applicant
          Credit
        </Typography.Text>
      </Col>

      <Col span={4} key="contractMonthlyFee" className={styles.textFormColumn}>
        <Typography.Text>
          <span className={styles.formLabel}>
            Contract Monthly Applicant Fee:{" "}
          </span>
          {monthlyApplicantsFee
            ? `$${monthlyApplicantsFee}`
            : "Not set in contract"}
        </Typography.Text>
      </Col>

      <Col span={4} key="target" className={styles.textFormColumn}>
        <Typography.Text>
          <span className={styles.formLabel}>Target: </span>
          {target}
        </Typography.Text>
      </Col>

      <Col span={4} key="totalCredits" className={styles.textFormColumn}>
        <Typography.Text>
          <span className={styles.formLabel}>Total Credits: </span>
          {applicantCreditSettings.total}
        </Typography.Text>
      </Col>

      <Col span={6} key="assignedCredits" className={styles.textFormColumn}>
        <Typography.Text>
          <span className={styles.formLabel}>Assigned Credits: </span>
          {applicantCreditSettings.assigned}
        </Typography.Text>
      </Col>

      <Col
        span={4}
        key="cyclesToAdd"
        className={styles.formColumn}
        style={{ display: "flex", alignItems: "center" }}
      >
        <Form.Item
          name="cyclesToAdd"
          label={<span className={styles.formLabel}>Cycles to Add</span>}
        >
          <InputNumber />
        </Form.Item>
      </Col>

      <Col
        span={4}
        key="newApplicantCredits"
        className={styles.formColumn}
        style={{ display: "flex", alignItems: "center" }}
      >
        <span className={styles.formLabel}>{getCheckboxLabel()}</span>
        <Form.Item
          className={styles.formItem}
          name="newApplicantCredits"
          valuePropName="checked"
          style={{ marginBottom: 0 }}
        >
          <Checkbox
            disabled={applicantCreditSettings.suggestedCredits === 0}
            style={{ marginLeft: "8px" }}
          />
        </Form.Item>
      </Col>
    </>
  );
}

ApplicantsPlatformCreditConfiguration.propTypes = {
  target: number,
  applicantCreditSettings: any,
  form: any,
};
