import React, { useState, useEffect } from "react";
import { Button, Form, Input, InputNumber, message, Modal, Select } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useForm, useWatch } from "antd/lib/form/Form";
import { object } from "prop-types";
import MoneyInput from "../shared/MoneyInput";
import {
  useCreateContractDraft,
  useUpdateContractDraft,
} from "../../api/contractDrafts";

export function EditContractDraftButton({ contractDraft }) {
  const [open, setOpen] = useState(false);
  const [form] = useForm();
  const contractType = useWatch("contractType", form);

  const onCancel = () => setOpen(false);
  const updateContractDraft = useUpdateContractDraft(
    contractDraft.contractDraftId
  );

  const { isLoading, isSuccess, isError, error } = updateContractDraft;

  const onSubmit = async () => {
    let values = null;
    try {
      values = await form.validateFields();
    } catch (err) {
      return;
    }

    updateContractDraft.mutate({ body: values });
  };

  // Update form state when the draft is updated
  useEffect(() => {
    if (contractDraft) {
      form.setFieldsValue(contractDraft);
    }
  }, [contractDraft]);

  // Toast messages for success & errors
  useEffect(() => {
    if (isError) {
      message.error(error?.message || "An error occurred");
    }
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      setOpen(false);
      message.success("Contract draft updated!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <>
      <Button
        icon={<EditOutlined />}
        onClick={e => {
          e.stopPropagation();
          setOpen(true);
        }}
        type="primary"
      >
        Edit
      </Button>
      <Modal
        title="Edit Contract Draft"
        visible={open}
        onCancel={onCancel}
        footer={[
          <Button key="1" disabled={isLoading} onClick={onCancel}>
            Cancel
          </Button>,
          <Button key="2" type="primary" onClick={onSubmit} loading={isLoading}>
            Submit
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" initialValues={contractDraft}>
          <Form.Item label="Source ID" name="sourceId" required>
            <Input />
          </Form.Item>
          <Form.Item label="Contract Type" name="contractType" required>
            <Select
              options={[
                {
                  value: "targets_contract",
                },
                {
                  value: "targets_campaign",
                },
                {
                  value: "applicants",
                },
              ]}
            />
          </Form.Item>
          <div style={{ display: "flex" }}>
            <Form.Item
              label="Platform Credits"
              name="platformCredits"
              style={{ width: "50%" }}
              required
              rules={[
                {
                  required: true,
                  type: "number",
                  min: 0,
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label="Duration in Months"
              name="duration"
              style={{ width: "50%" }}
              required
              rules={[
                {
                  required: true,
                  type: "number",
                  min: 0,
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
          </div>
          <div style={{ display: "flex" }}>
            <Form.Item
              label="Platform Fee"
              name="platformFee"
              style={{ width: "50%" }}
              required
            >
              <MoneyInput />
            </Form.Item>
            <Form.Item
              label="Platform Service Fee"
              name="platformServiceFee"
              style={{ width: "50%" }}
              required
            >
              <MoneyInput />
            </Form.Item>
          </div>
          {contractType === "applicants" && (
            <Form.Item
              label="Monthly Applicants Fee"
              name="monthlyApplicantsFee"
              style={{ width: "50%" }}
              required
            >
              <MoneyInput />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
}

EditContractDraftButton.propTypes = {
  contractDraft: object,
};

export function CreateContractDraftButton() {
  const [open, setOpen] = useState(false);
  const [form] = useForm();
  const contractType = useWatch("contractType", form);

  const onCancel = () => setOpen(false);
  const createContractDraft = useCreateContractDraft();

  const { isLoading, isSuccess, isError, error } = createContractDraft;

  const onSubmit = async () => {
    let values = null;
    try {
      values = await form.validateFields();
    } catch (err) {
      return;
    }

    createContractDraft.mutate(values);
  };

  // Toast messages for success & errors
  useEffect(() => {
    if (isError) {
      message.error(error?.message || "An error occurred");
    }
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      setOpen(false);
      message.success("Contract draft created!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <>
      <Button
        icon={<PlusOutlined />}
        onClick={e => {
          e.stopPropagation();
          setOpen(true);
        }}
        type="primary"
      >
        New Draft
      </Button>
      <Modal
        title="Create Contract Draft"
        visible={open}
        onCancel={onCancel}
        footer={[
          <Button key="1" disabled={isLoading} onClick={onCancel}>
            Cancel
          </Button>,
          <Button key="2" type="primary" onClick={onSubmit} loading={isLoading}>
            Submit
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            contractType: "targets_contract",
            platformCredits: 100,
            platformFee: "40.00",
            monthlyApplicantsFee: "500.00",
            platformServiceFee: "1.50",
            duration: 3,
          }}
        >
          <Form.Item label="Company Name" name="companyName" required>
            <Input />
          </Form.Item>
          <Form.Item label="Source ID" name="sourceId" required>
            <Input />
          </Form.Item>
          <Form.Item label="Contract Type" name="contractType" required>
            <Select
              options={[
                {
                  value: "targets_contract",
                },
                {
                  value: "targets_campaign",
                },
                {
                  value: "applicants",
                },
              ]}
            />
          </Form.Item>
          <div style={{ display: "flex" }}>
            <Form.Item
              label="Platform Credits"
              name="platformCredits"
              style={{ width: "50%" }}
              required
              rules={[
                {
                  required: true,
                  type: "number",
                  min: 0,
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label="Duration in Months"
              name="duration"
              style={{ width: "50%" }}
              required
              rules={[
                {
                  required: true,
                  type: "number",
                  min: 3,
                  max: 24,
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
          </div>
          <div style={{ display: "flex" }}>
            <Form.Item
              label="Platform Fee"
              name="platformFee"
              style={{ width: "50%" }}
              required
            >
              <MoneyInput />
            </Form.Item>
            <Form.Item
              label="Platform Service Fee"
              name="platformServiceFee"
              style={{ width: "50%" }}
              required
            >
              <MoneyInput />
            </Form.Item>
          </div>
          {contractType === "applicants" && (
            <Form.Item
              label="Monthly Applicants Fee"
              name="monthlyApplicantsFee"
              style={{ width: "50%" }}
              required
            >
              <MoneyInput />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
}

EditContractDraftButton.propTypes = {
  contractDraft: object,
};
