import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { result } from "../redux/shared/sharedActions";
import { PROFILE_REVIEW_PATH } from "./profileReview";
import {
  createAuthenticatedRequest,
  defaultQueryOptions,
  defaultMutationOptions,
  executeRequest,
} from "./utils";

export const USERS_PATH = "users";

export function useUsers({ includes, params, ...overrideOptions }) {
  const dispatch = useDispatch();

  return useQuery(
    [USERS_PATH, includes, params],
    () => fetchUsers(dispatch, { includes, params }),
    { ...defaultQueryOptions, ...overrideOptions }
  );
}

// Execute fetch request for users
export async function fetchUsers(dispatch, options) {
  const requestObject = await createAuthenticatedRequest(
    USERS_PATH,
    dispatch,
    options
  );

  return executeRequest(requestObject);
}

export function useCreateUser() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(body => createUser(body, dispatch), {
    ...defaultMutationOptions,
    onSuccess: () => {
      queryClient.invalidateQueries(USERS_PATH);
    },
  });
}

// Execute a POST to create a new user
export async function createUser(body, dispatch) {
  const requestObject = await createAuthenticatedRequest(USERS_PATH, dispatch, {
    method: "POST",
    body: JSON.stringify(body),
  });

  return executeRequest(requestObject);
}

export function useDeleteUser(options) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(userId => deleteUser(userId, dispatch, options), {
    onSuccess: () => {
      queryClient.invalidateQueries(USERS_PATH);
    },
    ...defaultMutationOptions,
  });
}

// Execute a DELETE to delete a user
export async function deleteUser(userId, dispatch, options) {
  const path = `${USERS_PATH}/${userId}`;
  const requestObject = await createAuthenticatedRequest(path, dispatch, {
    method: "DELETE",
    ...options,
  });

  return executeRequest(requestObject);
}

export function useValidateInboundUser() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const path = `${USERS_PATH}/validateInbound`;

  return useMutation(body => validateInboundUser(path, body, dispatch), {
    ...defaultMutationOptions,
    onSuccess: () => {
      queryClient.invalidateQueries(path);
      queryClient.invalidateQueries(`${PROFILE_REVIEW_PATH}/nextUser`);
    },
    onError: err => {
      // We swallow all errors from this endpoint and just go to the next user. There are
      // valid errors but the frontend can't really react to them so we don't brick the UI.
      console.error(err);
      queryClient.invalidateQueries(path);
      queryClient.invalidateQueries(`${PROFILE_REVIEW_PATH}/nextUser`);
    },
  });
}

// Execute a PUT request to validate a new inbound user
export async function validateInboundUser(path, body, dispatch) {
  const requestObject = await createAuthenticatedRequest(path, dispatch, {
    method: "PUT",
    body: JSON.stringify(body),
  });

  return executeRequest(requestObject);
}

export function useUpdateUser() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    ({ userId, body, ...options }) =>
      patchUser(`${USERS_PATH}/${userId}`, body, dispatch, options),
    {
      ...defaultMutationOptions,
      onSuccess: data => {
        queryClient.invalidateQueries([USERS_PATH, data.userId]);
      },
    }
  );
}

// Execute a PATCH request to update user
export async function patchUser(path, body, dispatch, options) {
  const requestObject = await createAuthenticatedRequest(path, dispatch, {
    method: "PATCH",
    body: JSON.stringify(body),
    ...options,
  });

  return executeRequest(requestObject);
}

export function useInfluencerToken({ userId, ...overrideOptions }) {
  const dispatch = useDispatch();
  const path = `services/createToken`;

  return useQuery(
    [path, userId],
    async () => {
      const requestObject = await createAuthenticatedRequest(path, dispatch, {
        params: { userId },
      });
      return executeRequest(requestObject);
    },
    { ...defaultQueryOptions, ...overrideOptions }
  );
}

export function useUpdateUserEligibility({ overrideOptions } = {}) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ userId }) => {
      const path = `userEligibility/update/${userId}`;
      const requestObject = await createAuthenticatedRequest(path, dispatch, {
        method: "POST",
      });
      return executeRequest(requestObject);
    },
    {
      ...defaultMutationOptions,
      ...overrideOptions,
      onSuccess: data => {
        queryClient.invalidateQueries([USERS_PATH]);
      },
    }
  );
}

export function useSendUserNotification({ overrideOptions } = {}) {
  const dispatch = useDispatch();

  return useMutation(
    async ({ userId }) => {
      const path = `users/${userId}/testNotification`;
      const requestObject = await createAuthenticatedRequest(path, dispatch, {
        method: "POST",
      });
      return executeRequest(requestObject);
    },
    {
      ...defaultMutationOptions,
      ...overrideOptions,
    }
  );
}
