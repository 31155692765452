import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { array, bool, object } from "prop-types";
import { Button, Checkbox, Modal, Tag, Typography } from "antd";
import DataTable from "../shared/DataTable/DataTable";
import { getColumnsFromDataSource } from "../shared/DataTable/utils";
import { isLoading as setLoading } from "../../redux/shared/sharedActions";
import GenericPageHeader from "../shared/GenericPageHeader/GenericPageHeader";
import { getTagColor } from "../../utils/getTagColor";
import StateFilter from "../Contracts/ContractHeader/StateFilter";
import { useHasRoles } from "../../utils/checkAuth";
import { useUpdateCampaigns } from "../../api/campaigns";
import { useUpdateContract } from "../../api/contracts";
import { displayDate, toCalendarDate } from "../../utils/dates";

export default function CampaignsTable({
  campaigns = [],
  isLoading,
  useFilter,
  showSelection = false,
  contract,
}) {
  const contractId = contract?.contractId;

  const history = useHistory();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("not_disqualified");

  let filteredCampaigns = campaigns;
  if (useFilter) {
    filteredCampaigns = campaigns.filter(campaign => {
      switch (filter) {
        case "not_disqualified":
          return campaign.state !== "disqualified";
        case "none":
          return true;
        default:
          return campaign.state === filter;
      }
    });
  }

  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  const columns = [
    "productName",
    "state",
    "code",
    "startDate",
    "defaultPrice",
    "target",
    "totalOnboards",
    //states
    "not_started",
    "started",
    "done",
    "followed_up",
    "not_interested",
  ];

  const isAuthorized = useHasRoles(["admin"]);
  if (showSelection && isAuthorized) {
    columns.splice(2, 0, "selection");
  }

  const allSelected = filteredCampaigns.every(c => c.selectionActive);
  const updateCampaigns = useUpdateCampaigns();
  const updateContract = useUpdateContract(contractId);
  const [campaignsLoading, setCampaignsLoading] = useState(false);

  const [pendingCampaignIds, setPendingCampaignIds] = useState([]);
  // Track whether our update click came from the "toggle all" button, in which
  // case we should update the contract start date as well as the campaign start
  // dates.
  const [shouldUpdateContract, setShouldUpdateContract] = useState(false);

  const updateCampaignsSelection = async ({
    campaignIds,
    selectionActive,
    startDate,
    shouldUpdateContract,
  }) => {
    const updates = {
      selectionActive,
    };

    if (startDate) {
      updates.startDate = startDate;
    }

    setCampaignsLoading(true);

    try {
      const promises = [updateCampaigns.mutate({ campaignIds, updates })];
      if (shouldUpdateContract) {
        promises.push(updateContract.mutate({ body: { startDate } }));
      }
      await Promise.all(promises);
    } finally {
      setCampaignsLoading(false);
      setPendingCampaignIds([]);
      setShouldUpdateContract(false);
    }
  };

  const toggleCampaigns = async ({
    campaignIds,
    selectionActive,
    fromToggleAll = false,
  }) => {
    // First, check if all the campaigns are inactive and have 0 onboards
    let showModal = true;
    const campaignsToToggle = campaigns.filter(c =>
      campaignIds.includes(c.campaignId)
    );
    for (const campaign of campaignsToToggle) {
      if (campaign.totalOnboards > 0 || campaign.selectionActive) {
        showModal = false;
      }
    }

    // Show our modal confirming we want to sync start dates
    if (showModal) {
      setPendingCampaignIds(campaignIds);
      setShouldUpdateContract(fromToggleAll);
      return;
    }

    // We didn't need to confirm, so we just toggle the campaigns
    updateCampaignsSelection({ campaignIds, selectionActive });
  };

  const toggleAllCampaigns = () =>
    toggleCampaigns({
      campaignIds: filteredCampaigns.map(c => c.campaignId),
      selectionActive: !allSelected,
      fromToggleAll: true,
    });

  const currentCalendarDate = toCalendarDate(new Date());

  return (
    <>
      {history.location.pathname.startsWith("/campaigns") && (
        <GenericPageHeader
          title="Campaigns"
          extra={<StateFilter filter={filter} setFilter={setFilter} />}
        />
      )}
      <DataTable
        size="small"
        style={{ marginBottom: "12px" }}
        dataSource={filteredCampaigns}
        rowKey="campaignId"
        pagination={false}
        columns={getColumnsFromDataSource(campaigns, columns, {
          productName: {
            render: (text, record) => (
              <a href={`/#/campaigns/${record.campaignId}`}>{text}</a>
            ),
          },
          state: {
            render: text => <Tag color={getTagColor(text)}>{text}</Tag>,
          },
          selection: {
            title: () => (
              <div style={{ display: "flex", gap: "6px" }}>
                <Checkbox
                  checked={allSelected}
                  onClick={toggleAllCampaigns}
                  disabled={campaignsLoading || contract?.isApplicants}
                />
                <Typography.Text>Selection</Typography.Text>
              </div>
            ),
            render: (_, campaign) => {
              const {
                selectionActive,
                rulesTable,
                featuredPriority,
                sortOffset,
              } = campaign;

              const isFeatured = Number.isInteger(featuredPriority);
              const hasOffset = Number.isInteger(sortOffset);

              let offsets = "";
              if (isFeatured) {
                offsets += featuredPriority;
              }
              if (hasOffset) {
                // if > 0, add a + sign, else if negative do not prefix
                offsets += ` ${sortOffset >= 0 ? "+" : ""}${sortOffset}`;
              }

              return (
                <div style={{ display: "flex", gap: "6px" }}>
                  <Checkbox
                    checked={selectionActive}
                    onClick={e => {
                      toggleCampaigns({
                        campaignIds: [campaign.campaignId],
                        selectionActive: !selectionActive,
                      });
                      e.stopPropagation();
                    }}
                    disabled={campaignsLoading || contract?.isApplicants}
                  />
                  <Tag
                    color={isFeatured ? "purple" : "default"}
                    style={{ marginRight: 0 }}
                  >
                    {rulesTable}
                  </Tag>
                  {offsets && <Typography.Text>{offsets}</Typography.Text>}
                </div>
              );
            },
          },
          //set column props by column name
          startDate: {
            render: text => displayDate(text).format("MMM Do YYYY"),
          },
          code: {
            title: "ASIN",
          },
          totalOnboards: {
            title: "Onboards",
          },
          followed_up: {
            title: "CTR",
          },
          defaultPrice: {
            title: "Price",
            render: text => `$${text}`,
          },
        })}
        onRow={record => {
          return {
            onClick: () => history.push(`/campaigns/${record.campaignId}`), // click row
            // onDoubleClick: event => {}, // double click row
            // onContextMenu: event => {}, // right button click row
            // onMouseEnter: event => {}, // mouse enter row
            // onMouseLeave: event => {} // mouse leave row
          };
        }}
      />
      <Modal
        visible={pendingCampaignIds.length > 0}
        closable={false}
        title={`Sync start dates for ${
          shouldUpdateContract ? "contract" : "new campaigns"
        }`}
        footer={[
          <Button
            key="1"
            disabled={campaignsLoading}
            onClick={
              // On confirm modal cancel, we update the selected campaigns to active without
              // resetting the start date
              () =>
                updateCampaignsSelection({
                  campaignIds: pendingCampaignIds,
                  selectionActive: true,
                })
            }
          >
            Don't Update
          </Button>,
          <Button
            key="2"
            type="primary"
            onClick={
              // On confirm modal submit, we update the selected campaigns to active and
              // also reset the start date
              () =>
                updateCampaignsSelection({
                  campaignIds: pendingCampaignIds,
                  selectionActive: true,
                  startDate: currentCalendarDate,
                  shouldUpdateContract,
                })
            }
            loading={campaignsLoading}
          >
            Update
          </Button>,
        ]}
      >
        Do you want to update the start date for the selected campaigns to
        today, {moment().format("MMM Do YYYY")}?
        {shouldUpdateContract && (
          <b> This will also update the contract start date.</b>
        )}
      </Modal>
    </>
  );
}

CampaignsTable.propTypes = {
  campaigns: array,
  isLoading: bool,
  useFilter: bool,
  showSelection: bool,
  contract: object,
};
