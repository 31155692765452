import React from "react";
import { Descriptions, Tooltip } from "antd";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent); //if Mobile

export default ({ contract }) => {
  const columns = contract.isApplicants ? 9 : 8;
  return (
    <Descriptions size="small" column={isMobile ? 2 : columns}>
      <Descriptions.Item label="Target">
        <Tooltip title={contract.manualTarget ? "contract" : "campaigns"}>
          {contract.target}
        </Tooltip>
      </Descriptions.Item>
      {contract.isApplicants && (
        <>
          <Descriptions.Item label="Applicants">
            {contract.totalApplicants}
          </Descriptions.Item>
        </>
      )}
      <Descriptions.Item label="Contract Fee">
        ${contract.fee}
      </Descriptions.Item>
      <Descriptions.Item label="Total Onboards">
        {contract.totalOnboards}
      </Descriptions.Item>
      <Descriptions.Item label="Not Started">
        {contract.not_started}
      </Descriptions.Item>
      <Descriptions.Item label="Started">{contract.started}</Descriptions.Item>
      <Descriptions.Item label="Done">{contract.done}</Descriptions.Item>
      <Descriptions.Item label="CTR">{contract.followed_up}</Descriptions.Item>
      <Descriptions.Item label="Not Interested">
        {contract.not_interested}
      </Descriptions.Item>
    </Descriptions>
  );
};
